"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PDFObject = void 0;
var React = require("react");
var pdfobject = require("pdfobject");
var PDFObject = /** @class */ (function (_super) {
    __extends(PDFObject, _super);
    function PDFObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.embed = function () {
            var _a = _this.props, url = _a.url, containerId = _a.containerId, containerProps = _a.containerProps, options = __rest(_a, ["url", "containerId", "containerProps"]);
            if (pdfobject) {
                pdfobject.embed(url, "#" + containerId, options);
            }
        };
        return _this;
    }
    PDFObject.pdfobjectversion = function () {
        return pdfobject.pdfobjectversion;
    };
    PDFObject.supportsPDFs = function () {
        return pdfobject.supportsPDFs;
    };
    PDFObject.prototype.componentDidMount = function () {
        this.embed();
    };
    PDFObject.prototype.componentDidUpdate = function (prevProps) {
        // check for different props.url
        if (prevProps.url !== this.props.url) {
            this.embed();
        }
    };
    PDFObject.prototype.render = function () {
        return React.createElement("div", __assign({}, this.props.containerProps, { id: this.props.containerId }));
    };
    PDFObject.defaultProps = {
        width: '100%',
        height: '100%',
        containerId: 'pdfobject',
        forcePDFJS: false,
        assumptionMode: true,
    };
    return PDFObject;
}(React.PureComponent));
exports.PDFObject = PDFObject;
